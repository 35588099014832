import PropTypes from 'prop-types';
import Slider from '@activebrands/core-web/components/Slider';
import useReInitWidgets from '@activebrands/core-web/libs/lipscore/hooks/useReInitWidgets';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { SliderNav } from 'components/Header/BasketOverlay/BasketUpsell/Slidernav';
import ProductCard from 'components/product/ProductCard';
import Heading from 'components/text/Heading';

const SliderWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '40px 0',
});

const BasketUpsell = ({ heading = '', products = [] }) => {
    const slider = createSlider();
    const [css] = useStyletron();

    useReInitWidgets(products);

    if (products.length < 1) {
        return null;
    }

    return (
        <SliderWrapper>
            <div
                className={css({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '12px',
                })}
            >
                {heading && <Heading fontKeys="Primary/18_100_-3" $style={{ alignSelf: 'end' }}>{heading}</Heading>}
                {products.length > 1 ? <SliderNav slider={slider} /> : null}
            </div>

            <Slider
                isDraggable
                $style={{ height: '100%' }}
                slider={slider}
                slidesPerView={1.5}
                snapAlign="none"
                spaceBetween="8px"
            >
                {products.map((product, i) => (
                    <ProductCard
                        {...product}
                        media={product.media.length > 0 ? product.media.slice(0, 1) : []}
                        key={product.id}
                        position={i}
                    />
                ))}
            </Slider>
        </SliderWrapper>
    );
};

BasketUpsell.propTypes = {
    heading: PropTypes.string,
    products: PropTypes.array,
};

export default BasketUpsell;
